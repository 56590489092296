
import { Component, Prop } from "vue-property-decorator";
import Vue from "@/interfaces/vue";
import { Route } from "vue-router";
import { get, del, put, post } from "@/api";
import { Visit, Dictionary, Therapist } from "@/interfaces";
import vSelect from "vue-select";
import "@/scss/vue-select.css";

Component.registerHooks(["beforeRouteLeave"]);

interface NewTherapistVisitType extends Visit.TherapistVisitType {
  name: string;
}

@Component({
  components: {
    vSelect,
  },
})
export default class InfoAvailabilityComponent extends Vue {
  visitTypes: NewTherapistVisitType[] = [];
  checkedVisitTypes: NewTherapistVisitType[] = [];
  oldCheckedVisitTypes: NewTherapistVisitType[] = [];
  currency: string | null = null;
  specializations: string[] = [];
  isDataFilledCorrect = true;
  default_price = 70;
  default_duration = 30;
  medical_service = true;

  @Prop({
    default: function() {
      return null;
    },
  })
  therapist!: Therapist.TherapistInfo | null;

  @Prop()
  firstTime!: boolean;

  async created() {
    this.getSpecializationsAndSetVisitTypes();
    if (this.therapist) this.currency = this.therapist.accepted_currency;
  }

  async cancel() {
    this.isDataFilledCorrect = true;
    this.checkedVisitTypes = JSON.parse(
      JSON.stringify(this.oldCheckedVisitTypes)
    );
    this.visitTypes.forEach((type, index) => {
      const id = this.oldCheckedVisitTypes.findIndex(
        (item) => item.visit_type === type.visit_type
      );
      if (id !== -1) {
        this.visitTypes[index].id = this.oldCheckedVisitTypes[id].id;
        this.visitTypes[index].specialization = this.oldCheckedVisitTypes[
          id
        ].specialization;
        this.visitTypes[index].visit_type = this.oldCheckedVisitTypes[
          id
        ].visit_type;
        this.visitTypes[index].duration = this.oldCheckedVisitTypes[
          id
        ].duration;
        this.visitTypes[index].price = parseInt(
          this.oldCheckedVisitTypes[id].price.toString()
        );
        this.visitTypes[index].medical_service = this.oldCheckedVisitTypes[
          id
        ].medical_service;
      } else {
        this.visitTypes[index].duration = this.default_duration;
        this.visitTypes[index].price = this.default_price;
        this.visitTypes[index].medical_service = this.medical_service;
      }
    });
  }

  async getSpecializationsAndSetVisitTypes() {
    try {
      this.visitTypes = [];
      let therapistCheckedVisitTypes: Visit.TherapistVisitType[] = [];
      const { data: visitTypes } = await get.therapistVisitTypes();
      if (visitTypes)
        therapistCheckedVisitTypes = JSON.parse(JSON.stringify(visitTypes));
      const { data: dictionaries } = await get.dict();
      if (dictionaries && this.therapist) {
        const specialization = [...dictionaries.specialization_ids].filter(
          (e: Dictionary.SpecializationId) =>
            this.therapist?.specializations.indexOf(e.id) !== -1
        )[0];
        this.specializations.push(specialization.specialization);
        const visit_types: Dictionary.DictionaryVisitType[] = Array.from(
          dictionaries.visit_types.filter(
            (type: Dictionary.DictionaryVisitType) =>
              type.for_specializations.indexOf(specialization.id) !== -1
          )
        );
        visit_types.forEach((type: Dictionary.DictionaryVisitType) => {
          this.visitTypes.push({
            id: null,
            specialization: specialization.id,
            name: type.name,
            visit_type: type.id,
            duration: type.default_duration,
            price: this.default_price,
            medical_service: this.medical_service,
          });
        });
        if (this.firstTime && therapistCheckedVisitTypes.length === 0) {
          this.checkedVisitTypes = JSON.parse(JSON.stringify(this.visitTypes));
          this.oldCheckedVisitTypes = JSON.parse(
            JSON.stringify(this.visitTypes)
          );
        } else if (therapistCheckedVisitTypes.length !== 0) {
          this.visitTypes.forEach((type, index) => {
            const id = therapistCheckedVisitTypes.findIndex(
              (item) => item.visit_type === type.visit_type
            );
            if (id !== -1) {
              this.visitTypes[index].id = therapistCheckedVisitTypes[id].id;
              this.visitTypes[index].specialization =
                therapistCheckedVisitTypes[id].specialization;
              this.visitTypes[index].visit_type =
                therapistCheckedVisitTypes[id].visit_type;
              this.visitTypes[index].duration =
                therapistCheckedVisitTypes[id].duration;
              this.visitTypes[index].price = parseInt(
                therapistCheckedVisitTypes[id].price.toString()
              );
              this.visitTypes[index].medical_service =
                therapistCheckedVisitTypes[id].medical_service;
            }
          });
          this.checkedVisitTypes = this.visitTypes.filter((type) => type.id);
          this.oldCheckedVisitTypes = JSON.parse(
            JSON.stringify(this.checkedVisitTypes)
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  get durations() {
    const maxDuration = 120;
    const minDuration = 10;
    const durationStep = 5;
    const durations = [];
    for (let i = minDuration; i < maxDuration; i += durationStep) {
      durations.push(i);
    }
    return durations;
  }

  async submit() {
    try {
      if (
        this.isSomethingChanged ||
        (this.firstTime && this.$store.state.stage === 3)
      ) {
        const oldVisitTypeIds = this.oldCheckedVisitTypes.map(
          (visitType) => visitType.visit_type
        );
        const newVisitTypeIds = this.checkedVisitTypes.map(
          (visitType) => visitType.visit_type
        );

        const newVisitTypes = this.checkedVisitTypes
          .filter(
            (visitType: Visit.TherapistVisitType) =>
              oldVisitTypeIds.indexOf(visitType.visit_type) === -1 ||
              (this.firstTime && this.$store.state.stage === 3)
          )
          .map((visitType: Visit.TherapistVisitType) => {
            if (this.therapist) {
              return {
                specialization: this.therapist.specializations[0],
                visit_type: visitType.visit_type,
                duration: visitType.duration,
                price: visitType.price,
                medical_service: visitType.medical_service,
              };
            }
          });

        const changedVisitTypes = this.checkedVisitTypes
          .filter((visitType: Visit.TherapistVisitType) => {
            if (
              oldVisitTypeIds.indexOf(visitType.visit_type) !== -1 &&
              !(this.firstTime && this.$store.state.stage === 3)
            ) {
              const index = this.oldCheckedVisitTypes.findIndex(
                (item) => item.visit_type === visitType.visit_type
              );
              return (
                JSON.stringify(visitType) !==
                JSON.stringify(this.oldCheckedVisitTypes[index])
              );
            }
          })
          .map((visitType: Visit.TherapistVisitType) => {
            if (this.therapist) {
              return {
                id: visitType.id,
                specialization: this.therapist.specializations[0],
                visit_type: visitType.visit_type,
                duration: visitType.duration,
                price: visitType.price,
                medical_service: visitType.medical_service,
              };
            }
          });

        const deletedVisitTypes = this.oldCheckedVisitTypes
          .filter((visitType: Visit.TherapistVisitType) => {
            if (
              newVisitTypeIds.indexOf(visitType.visit_type) === -1 &&
              !(this.firstTime && this.$store.state.stage === 3)
            ) {
              return true;
            }
          })
          .map((visitType: Visit.TherapistVisitType) => {
            if (this.therapist) {
              return {
                id: visitType.id,
                specialization: this.therapist.specializations[0] || 0,
                visit_type: visitType.visit_type,
                duration: visitType.duration,
                price: visitType.price,
                medical_service: visitType.medical_service,
              };
            }
          });
        if (newVisitTypes.length !== 0) {
          for (const visitType of newVisitTypes) {
            if (visitType) await post.visitType(visitType);
          }
        }

        if (changedVisitTypes.length !== 0) {
          for (const visitType of changedVisitTypes) {
            if (visitType) await put.visitType(visitType);
          }
        }

        if (deletedVisitTypes.length !== 0) {
          for (const visitType of deletedVisitTypes) {
            if (visitType && visitType.id) await del.visitType(visitType.id);
          }
        }
        this.oldCheckedVisitTypes = JSON.parse(
          JSON.stringify(this.checkedVisitTypes)
        );
        if (this.firstTime) {
          const { data: therapist } = await get.therapistInfo();
          this.$store.commit("setStage", therapist.completed_fillings);
        } else this.$openModal({ component: "SavedSuccessfullyModal" });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async save() {
    if (this.isDataFilled) {
      await this.submit();
    } else {
      this.isDataFilledCorrect = false;
    }
  }

  onlyNumber(event: any) {
    const keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault();
    }
  }

  get isDataFilled() {
    return (
      this.checkedVisitTypes.filter((type) => type.price.toString() === "")
        .length === 0
    );
  }

  get isSomethingChanged() {
    return (
      JSON.stringify(this.checkedVisitTypes.sort()) !==
      JSON.stringify(this.oldCheckedVisitTypes.sort())
    );
  }

  isSelected(id: number) {
    return (
      this.checkedVisitTypes.map((visit) => visit.visit_type).indexOf(id) !== -1
    );
  }

  isChecked(id: number) {
    return this.checkedVisitTypes
      .map((visit) => visit.visit_type)
      .indexOf(id) !== -1
      ? require(`@/assets/checked.png`)
      : require(`@/assets/unchecked.png`);
  }

  isCheckedMedicalService(id: number) {
    const visitTypeId = this.visitTypes
      .map((visit) => visit.visit_type)
      .indexOf(id);
    return this.visitTypes[visitTypeId].medical_service
      ? require(`@/assets/checked.png`)
      : require(`@/assets/unchecked.png`);
  }

  isNotCheckedMedicalService(id: number) {
    const visitTypeId = this.visitTypes
      .map((visit) => visit.visit_type)
      .indexOf(id);
    return this.visitTypes[visitTypeId].medical_service
      ? require(`@/assets/unchecked.png`)
      : require(`@/assets/checked.png`);
  }

  isActive(id: number) {
    return (
      this.checkedVisitTypes.map((visit) => visit.visit_type).indexOf(id) ===
        -1 || this.checkedVisitTypes.length > 1
    );
  }

  onChangeTime(event: any, index: number) {
    this.visitTypes[index].duration = event;
  }

  async beforeRouteLeave(to: Route, from: Route, next: any) {
    if (
      this.firstTime &&
      to.meta.requiresAuth &&
      to.path !== "/dashboard/myprofile/office"
    ) {
      if (this.isDataFilled) {
        await this.submit();
        next();
      }
      this.isDataFilledCorrect = false;
    } else if (
      (this.firstTime &&
        !this.isSomethingChanged &&
        to.path === "/dashboard/myprofile/office") ||
      (!this.firstTime && !this.isSomethingChanged) ||
      !this.$store.state.user
    ) {
      next();
    } else {
      this.$openModal({
        component: "InfoFillingModal",
        props: {
          next: () => next(),
          save: async () => await this.save(),
          cancel: () => this.cancel(),
          isDataFilled: this.isDataFilled,
        },
        this: this,
      });
    }
  }
}
